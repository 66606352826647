import "bootstrap";
import anime from "animejs/lib/anime.es.js";
import { createApp } from "vue";
import Card from "./components/Card.vue";
import Slider from "./components/Slider.vue";
import DrawForm from "./components/DrawForm.vue";
import ContactForm from "./components/ContactForm.vue";
import SectionTitle from "./components/SectionTitle.vue";
import DrawPlayground from "./components/DrawPlayground.vue";
import BenefitsListItem from "./components/BenefitsListItem.vue";
import CookieSettingsModal from "./components/CookieSettingsModal.vue";
import OpenCookieSettingsButton from "./components/OpenCookieSettingsButton.vue";
import TagList from "./components/TagList.vue";
import Tab from "./components/Tab.vue";
import store from "./store";

if (document.getElementById("cookie") !== null) {
    createApp({
        components: {
            "cookie-settings-modal-component": CookieSettingsModal,
        },
    })
        .use(store)
        .mount("#cookie");
}

if (document.getElementById("agent-section-title-one") !== null) {
    createApp({
        components: {
            "section-title-component": SectionTitle,
        },
    }).mount("#agent-section-title-one");
}

if (document.getElementById("agent-section-title-two") !== null) {
    createApp({
        components: {
            "section-title-component": SectionTitle,
        },
    }).mount("#agent-section-title-two");
}

if (document.getElementById("agent-section-title-three") !== null) {
    createApp({
        components: {
            "section-title-component": SectionTitle,
        },
    }).mount("#agent-section-title-three");
}

if (document.getElementById("section-title-one") !== null) {
    createApp({
        components: {
            "section-title-component": SectionTitle,
        },
    }).mount("#section-title-one");
}

if (document.getElementById("section-title-two") !== null) {
    createApp({
        components: {
            "section-title-component": SectionTitle,
        },
    }).mount("#section-title-two");
}

if (document.getElementById("section-title-three") !== null) {
    createApp({
        components: {
            "section-title-component": SectionTitle,
        },
    }).mount("#section-title-three");
}

// if (document.getElementById("section-title-three") !== null) {
//     createApp({
//         components: {
//             "section-title-component": SectionTitle,
//         },
//     }).mount("#section-title-four");
// }

if (document.getElementById("they-said") !== null) {
    createApp({
        components: {
            "slider-component": Slider,
        },
    }).mount("#they-said");
}

if (document.getElementById("tag-list") !== null) {
    createApp({
        components: {
            "tag-list": TagList,
        },
    }).mount("#tag-list");
}

if (document.getElementById("tab-component") !== null) {
    createApp({
        components: {
            "tab-component": Tab,
            "benefits-list-item-component": BenefitsListItem,
        },
    }).mount("#tab-component");
}

if (document.getElementById("what") !== null) {
    createApp({
        components: {
            "card-component": Card,
        },
    }).mount("#what");
}

if (document.getElementById("contact") !== null) {
    createApp({
        components: {
            "contact-form": ContactForm,
            "open-cookie-settings-button-component": OpenCookieSettingsButton,
        },
    })
        .use(store)
        .mount("#contact");
}

if (document.getElementById("draw") !== null) {
    createApp({
        components: {
            "draw-form": DrawForm,
            "draw-playground": DrawPlayground,
        },
    })
        .use(store)
        .mount("#draw");
}

if (document.getElementById("why") !== null) {
    const animationBoot = anime({
        targets: ".boot",
        keyframes: [{ top: -100 }, { top: -140 }],
        loop: 3,
        duration: 800,
        easing: "easeOutInBack",
        direction: "alternate",
        autoplay: false,
    });

    const animationRocks = anime({
        targets: [".rock-1", ".rock-2", ".rock-3"],
        translateY: 200,
        translateX: 40,
        opacity: 1,
        rotate: "2turn",
        duration: 500,
        autoplay: false,
        easing: "easeOutBounce",
        delay: anime.stagger(800, { start: 200 }),
    });

    let animationDone = false;

    window.onscroll = function () {
        const element = document.getElementById("why");

        if (
            !animationDone &&
            element.getBoundingClientRect().top < 400 &&
            element.getBoundingClientRect().top > 350
        ) {
            animationDone = true;
            animationBoot.play();
            animationRocks.play();
        }

        //TOP
        // if(element.getBoundingClientRect().top <= 0){
        //     console.log("TRIGGER: top of div reached.");
        // }
    };
}

// Elements
// const benefits = document.getElementById("benefits");

// Scroll magic
// const controller = new ScrollMagic.Controller();

// const scene = new ScrollMagic.Scene({
//     triggerHook: 0.1,
//     triggerElement: benefits,
// })
//     .setClassToggle(".benefits-list-item-number", "fade")
//     .reverse(false)
//     .addTo(controller);
